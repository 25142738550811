import { lazy } from "react";
import Loadable from "app/components/Loadable";

const AppForm = Loadable(lazy(() => import("./forms/AppForm")));
const AppMenu = Loadable(lazy(() => import("./menu/AppMenu")));
const AppIcon = Loadable(lazy(() => import("./icons/AppIcon")));
const AppProgress = Loadable(lazy(() => import("./AppProgress")));
const AppRadio = Loadable(lazy(() => import("./radio/AppRadio")));
const AppTable = Loadable(lazy(() => import("./tables/AppTable")));
const AppSwitch = Loadable(lazy(() => import("./switch/AppSwitch")));
const AppSlider = Loadable(lazy(() => import("./slider/AppSlider")));
const AppDialog = Loadable(lazy(() => import("./dialog/AppDialog")));
const AppButton = Loadable(lazy(() => import("./buttons/AppButton")));
const AppCheckbox = Loadable(lazy(() => import("./checkbox/AppCheckbox")));
const AppSnackbar = Loadable(lazy(() => import("./snackbar/AppSnackbar")));
const AppAutoComplete = Loadable(lazy(() => import("./auto-complete/AppAutoComplete")));
const AppExpansionPanel = Loadable(lazy(() => import("./expansion-panel/AppExpansionPanel")));
const Addtestiminials = Loadable(lazy(() => import("./Testimonials/Addtestiminials")));
const Addservices = Loadable(lazy(() => import("./services/Addservices")));
const CasestudyTags = Loadable(lazy(() => import("./CaseStudys/CasestudyTags")));
const Casestudy = Loadable(lazy(() => import("./CaseStudys/Addcasestudy")));
const AddBlogs = Loadable(lazy(() => import("./blogs/AddBlogs")));
const Dremzehome = Loadable(lazy(() => import("./dremzhome/LandingPage")));
const Dremzehomeemail = Loadable(lazy(() => import("./dremzhome/Homeemails")));
const Contactusqueries = Loadable(lazy(() => import("./Contactus/Contactusqueries")));
const Footercontent = Loadable(lazy(() => import("./Footercontent/Footercontent")));
const materialRoutes = [
  { path: "/material/table", element: <AppTable /> },
  { path: "/material/form", element: <AppForm /> },
  { path: "/material/buttons", element: <AppButton /> },
  { path: "/material/icons", element: <AppIcon /> },
  { path: "/material/progress", element: <AppProgress /> },
  { path: "/material/menu", element: <AppMenu /> },
  { path: "/material/checkbox", element: <AppCheckbox /> },
  { path: "/material/switch", element: <AppSwitch /> },
  { path: "/material/radio", element: <AppRadio /> },
  { path: "/material/slider", element: <AppSlider /> },
  { path: "/material/autocomplete", element: <AppAutoComplete /> },
  { path: "/material/expansion-panel", element: <AppExpansionPanel /> },
  { path: "/material/dialog", element: <AppDialog /> },
  { path: "/material/snackbar", element: <AppSnackbar /> },
  { path: "/material/Testimonials/Addtestiminials", element: <Addtestiminials /> },
  { path: "/material/services/Addservices", element: <Addservices /> },
  { path: "/material/CaseStudys/CasestudyTags", element: <CasestudyTags /> },
  { path: "/material/CaseStudys/Casestudy", element: <Casestudy /> },
  { path: "/material/blogs/AddBlogs", element: <AddBlogs /> },
  { path: "/material/dremzhome/LandingPage", element: <Dremzehome /> },
  { path: "/material/dremzhome/Homeemails", element: <Dremzehomeemail /> },
  { path: "/material/Contactus/Contactusqueries", element: <Contactusqueries /> },
  { path: "/material/Footercontent/Footercontent", element: <Footercontent /> }
  // { path: "/material/Blogs/AddBlogs", element: <AddBlogs /> }
];

export default materialRoutes;
